<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(btnSubmitClick)">
      <div class="row">
        <div class="col-md-6">
          <card class="stacked-form" title="Member Details">
            <h4 slot="header" class="card-title">Member Details</h4>
            <div class="form-horizontal">
              <div class="row">
                <label class="col-md-3 col-form-label">Guest Name</label>
                <div class="col-md-7 offset-md-1">
                  <div class="form-group">
                    <ValidationProvider
                      name="guest"
                      rules="required"
                      v-slot="{ failed }"
                    >
                      <el-select
                        name="guest"
                        class="select-info"
                        v-model="guestName"
                        filterable
                        remote
                        placeholder="Search Guest"
                        :remote-method="searchGuestName"
                        :loading="guestLoading"
                        :disabled="isDisabled()"
                      >
                        <el-option
                          v-for="(guest, i) in guest"
                          :key="i"
                          :label="showFullName(guest)"
                          :value="showFullName(guest)"
                          @click.native="autoCompleteMember(guest)"
                        >
                          <span>{{ showFullName(guest) }}</span>
                          <br />
                          <span>{{ guest.card_number }}</span>
                        </el-option>
                      </el-select>
                      <div
                        class="text-danger invalid-feedback error-padding-bottom"
                        style="display: block"
                      >
                        {{ failed ? `Please input guest name` : null }}
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="row">
                <label class="col-md-3 col-form-label">Membership No</label>
                <div class="col-md-7 offset-md-1">
                  <fg-input
                    disabled
                    placeholder="Membership No"
                    v-model="formData.card_number"
                  ></fg-input>
                </div>
              </div>
              <div class="row">
                <label class="col-md-3 col-form-label">Email Address</label>
                <div class="col-md-7 offset-md-1">
                  <fg-input
                    disabled
                    placeholder="Email Address"
                    v-model="memberData.email"
                  ></fg-input>
                </div>
              </div>
            </div>
          </card>
        </div>
        <div class="col-md-6">
          <card class="stacked-form" title="Level Adjustment">
            <h4 slot="header" class="card-title">Level Adjustment</h4>
            <div class="form-horizontal">
              <div class="row">
                <label class="col-md-3 col-form-label">Current Level</label>
                <div class="col-md-7 offset-md-1">
                  <fg-input
                    disabled
                    placeholder="Current Level"
                    v-model="memberData.current_level"
                  ></fg-input>
                </div>
              </div>
              <div class="row">
                <label class="col-md-3 col-form-label">Adjustment</label>
                <div class="col-md-7 offset-md-1">
                  <div class="form-group">
                    <ValidationProvider
                      name="adjust level"
                      rules="required"
                      v-slot="{ failed }"
                    >
                      <el-select
                        name="adjust level"
                        class="select-info"
                        placeholder="Select Level"
                        v-model="formData.adjust_level"
                        :disabled="isDisabled()"
                      >
                        <el-option
                          class="select-info"
                          v-for="item in levels"
                          :key="item.level"
                          :label="item.label"
                          :value="item.level"
                        ></el-option>
                      </el-select>
                      <div
                        class="text-danger invalid-feedback error-padding-bottom"
                        style="display: block"
                      >
                        {{ failed ? `Please select adjustment level` : null }}
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="row">
                <label class="col-md-3 col-form-label">Effective Date</label>
                <div class="col-md-7 offset-md-1">
                  <ValidationProvider
                    name="effective date"
                    rules="required"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      name="effective date"
                      :error="failed ? 'Please pick effective date' : null"
                      :hasSuccess="passed"
                    >
                      <el-date-picker
                        type="date"
                        placeholder="Select Date"
                        v-model="formData.effective_date"
                        :disabled="isDisabled()"
                      >
                      </el-date-picker>
                    </fg-input>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </card>
        </div>
        <div class="col-md-12">
          <card class="stacked-form" title="Adjustment Note">
            <h4 slot="header" class="card-title">Adjustment Note</h4>
            <div class="row">
              <div class="col-md-5">
                <ValidationProvider
                  name="note"
                  rules="required"
                  v-slot="{ passed, failed }"
                >
                  <el-input
                    name="note"
                    type="textarea"
                    :autosize="{ minRows: 2 }"
                    placeholder="Leave notes *"
                    v-model="formData.adjustment_note"
                    :error="failed ? 'Please leave some note' : null"
                    :hasSuccess="passed"
                    :disabled="isDisabled()"
                  >
                  </el-input>
                  <div
                    class="text-danger invalid-feedback error-padding-bottom"
                    style="display: block"
                  >
                    {{ failed ? `Please leave some note` : null }}
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </card>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <n-button native-type="submit" type="info" :disabled="isDisabled()">
            SUBMIT
          </n-button>
          <button class="btn btn-label" type="button" @click="$router.go(-1)">
            CANCEL
          </button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { Select, Option, DatePicker, Loading } from "element-ui";
import { mapActions, mapState, mapGetters } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { findUserRole } from "@/util/userRoles";

extend("required", required);

export default {
  props: {
    submit: Function
  },
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    [DatePicker.name]: DatePicker
  },
  data() {
    return {
      module_name: "membership_level_adjustment",
      guestName: "",
      formData: {},
      memberData: {},
      isEdit: false,
      levels: [
        {
          label: "Silver",
          level: "silver"
        },
        {
          label: "Gold",
          level: "gold"
        },
        {
          label: "Platinum",
          level: "platinum"
        }
      ]
    };
  },
  created() {
    const id = this.$route.params.id;
    const cardNumber = this.$route.query.card_number;

    if (typeof id !== "undefined") {
      this.isEdit = true;

      let loadingInstance = Loading.service({
        fullscreen: true
      });
      this.getLevelAdjustment(id)
        .then((response) => {
          this.guestName = this.showFullName(this.levelAdjustmentData.member);
          this.$set(
            this.formData,
            "card_number",
            this.levelAdjustmentData.card_number
          );
          this.$set(
            this.formData,
            "adjust_level",
            this.levelAdjustmentData.adjust_level
          );
          this.$set(
            this.formData,
            "effective_date",
            new Date(this.levelAdjustmentData.effective_date)
          );
          this.$set(
            this.formData,
            "adjustment_note",
            this.levelAdjustmentData.note
          );
          this.$set(
            this.memberData,
            "email",
            this.levelAdjustmentData.member.email
          );
          this.$set(
            this.memberData,
            "current_level",
            this.levelAdjustmentData.current_level
          );

          loadingInstance.close();
        })
        .catch((error) => {
          loadingInstance.close();

          this.$notify({
            message: "Data not found",
            timeout: 5000,
            icon: "now-ui-icons ui-1_bell-53",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
          });

          this.$router.push({ name: "Level Adjustment List" });
        });
    }

    if (typeof cardNumber !== "undefined" && !this.isEdit) {
      this.getDetail({ cardNumber: cardNumber, name: null })
        .then((response) => {
          this.guestName = this.detailData.fullName;
          this.$set(this.formData, "card_number", this.detailData.cardNumber);
          this.$set(this.memberData, "email", this.detailData.email);
          this.$set(this.memberData, "current_level", this.detailData.level);
        })
        .catch((error) => {
          this.$notify({
            message: "Member not found",
            timeout: 5000,
            icon: "now-ui-icons ui-1_bell-53",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger"
          });
        });
    }
  },
  computed: {
    ...mapState("pointAdjustment", ["guest", "guestLoading"]),
    ...mapState("levelAdjustment", ["levelAdjustment"]),
    ...mapGetters("levelAdjustment", ["levelAdjustmentData"]),
    ...mapState("auth", ["roles"]),
    ...mapState("members", ["detail"]),
    ...mapGetters("members", ["detailData"]),
  },
  methods: {
    ...mapActions("pointAdjustment", ["searchGuest"]),
    ...mapActions("levelAdjustment", ["getLevelAdjustment"]),
    ...mapActions("members", ["getDetail"]),
    showFullName(guest) {
      let full_name = "";
      if (guest.first_name) {
        full_name = full_name + guest.first_name;
      }
      if (guest.last_name) {
        full_name = full_name + " " + guest.last_name;
      }
      return full_name.trim();
    },
    searchGuestName(query) {
      this.searchGuest(query);
    },
    hasRole(role) {
      return findUserRole(role, "manual_level_adjustment");
    },
    autoCompleteMember(data) {
      this.$set(this.formData, "card_number", data.card_number);
      this.$set(this.memberData, "email", data.email);
      this.$set(this.memberData, "current_level", data.level);
    },
    isDisabled() {
      if (this.isEdit) {
        if (
          this.levelAdjustmentData.status !== "Pending" ||
          !this.hasRole("update")
        ) {
          return true;
        }
      }
      return false;
    },
    btnSubmitClick() {
      this.submit(this.formData, this.guestName);
    }
  }
};
</script>

<style>
</style>

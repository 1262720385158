<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submitForm)">
      <el-row :gutter="20">
        <el-col :md="12" :xs="24">
          <card class="card-padding" v-loading="loading.loadDetail">
            <h3 class="card-title">Member Details</h3>
            <el-row align="center">
              <el-col :span="6" class="col-form-label">
                <label>Guest Name</label>
              </el-col>
              <el-col :span="18">
                <div class="form-group">
                  <el-select
                    v-model="pointAdjustment.guestName"
                    filterable
                    remote
                    placeholder="Guest Name"
                    :remote-method="searchGuestName"
                    :loading="guestLoading"
                    style="display: block"
                    :disabled="isDisableInput"
                    class="select-info"
                  >
                    <el-option
                      v-for="(guest, i) in guest"
                      :key="i"
                      :label="guest.first_name + ' ' + guest.last_name"
                      :value="guest.first_name + ' ' + guest.last_name"
                      @click.native="autoCompleteMember(guest)"
                    >
                      <span>{{
                          guest.first_name + " " + guest.last_name
                        }}</span>
                      <br />
                      <span>{{ guest.card_number }}</span>
                    </el-option>
                  </el-select>
                </div>
              </el-col>
            </el-row>
            <el-row align="center">
              <el-col :span="6" class="col-form-label">
                <label>Membership No</label>
              </el-col>
              <el-col :span="18">
                <fg-input
                  v-model="pointAdjustment.card_number"
                  class="member-input"
                  disabled
                  placeholder="Membership No"
                ></fg-input>
              </el-col>
            </el-row>
            <el-row align="center">
              <el-col :span="6" class="col-form-label">
                <label>Email Address</label>
              </el-col>
              <el-col :span="18">
                <fg-input
                  v-model="pointAdjustment.email"
                  class="member-input"
                  disabled
                  placeholder="Email Address"
                ></fg-input>
              </el-col>
            </el-row>
          </card>
        </el-col>
        <el-col :md="12" :xs="24">
          <card class="card-padding" v-loading="loading.loadDetail">
            <h3 class="card-title">Visit Details</h3>
            <el-row align="center">
              <el-col :span="6" class="col-form-label">
                <label>Place of Visit</label>
              </el-col>
              <el-col :span="18">
                <div class="form-group" style="width: 50%">
                  <el-select
                    v-model="pointAdjustment.place"
                    class="select-info"
                    placeholder="Select..."
                    size="large"
                    :disabled="isDisableInput"
                  >
                    <el-option
                      v-for="(option, i) in placeOfVisit"
                      :key="i"
                      :label="option.langs[0].name"
                      :value="option.code"
                      class="select-info"
                    ></el-option>
                  </el-select>
                </div>
              </el-col>
            </el-row>
            <el-row align="center">
              <el-col :span="6" class="col-form-label">
                <label>Date of Visit</label>
              </el-col>
              <el-col :span="18">
                <el-row>
                  <el-col :span="10">
                    <fg-input :disabled="isDisableInput">
                      <el-date-picker
                        v-model="pointAdjustment.visit_from"
                        :picker-options="pickerOptions1"
                        placeholder="Start Date"
                        type="date"
                        value-format="yyyy-MM-dd"
                        :disabled="isDisableInput"
                      >
                      </el-date-picker>
                    </fg-input>
                  </el-col>
                  <el-col
                    :span="1"
                    class="col-form-label"
                    style="margin-left:15px"
                  >-</el-col
                  >
                  <el-col :span="10">
                    <fg-input :disabled="isDisableInput">
                      <el-date-picker
                        v-model="pointAdjustment.visit_to"
                        :picker-options="pickerOptions1"
                        placeholder="End Date"
                        type="date"
                        value-format="yyyy-MM-dd"
                        :disabled="isDisableInput"
                      >
                      </el-date-picker>
                    </fg-input>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <el-row align="center">
              <el-col :span="6" class="col-form-label">
                <label>Room Number</label>
              </el-col>
              <el-col :span="18">
                <fg-input
                  v-model="pointAdjustment.room_no"
                  class="member-input"
                  placeholder="Room Number"
                  :disabled="isDisableInput"
                ></fg-input>
              </el-col>
            </el-row>
          </card>
        </el-col>
        <el-col :xs="24">
          <card class="card-padding" v-loading="loading.loadDetail">
            <h3 class="card-title">Transaction Details</h3>
            <el-row :gutter="20">
              <el-col :md="12" :xs="24">
                <el-row align="center">
                  <el-col :span="6" class="col-form-label">
                    <label>Conf/Check No*</label>
                  </el-col>
                  <el-col :span="18">
                    <ValidationProvider
                      name="check-no"
                      rules="required"
                      v-slot="{ passed, failed }"
                    >
                      <fg-input
                        v-model="pointAdjustment.check_no"
                        class="member-input"
                        placeholder="Conf/Check No"
                        @blur="validateConfirmationNo"
                        :error="failed ? 'This field is required' : null"
                        :hasSuccess="passed"
                        :disabled="isDisableInput"
                      ></fg-input>
                    </ValidationProvider>
                  </el-col>
                </el-row>
                <el-row align="center">
                  <el-col :span="6" class="col-form-label">
                    <label>Transaction Date *</label>
                  </el-col>
                  <el-col :span="18">
                    <ValidationProvider
                      name="transaction-date"
                      rules="required"
                      v-slot="{ passed, failed }"
                    >
                      <fg-input
                        class="member-input"
                        :disabled="isDisableInput"
                        :error="failed ? 'This field is required' : null"
                        :hasSuccess="passed"
                      >
                        <el-date-picker
                          v-model="pointAdjustment.transaction_date"
                          :picker-options="pickerOptions1"
                          placeholder="Transaction Date"
                          type="date"
                          value-format="yyyy-MM-dd"
                          :disabled="isDisableInput"
                        >
                        </el-date-picker>
                      </fg-input>
                    </ValidationProvider>
                  </el-col>
                </el-row>
                <el-row align="center">
                  <el-col :span="6" class="col-form-label">
                    <label>Point Type *</label>
                  </el-col>
                  <el-col :span="18">
                    <ValidationProvider
                      name="point-type"
                      rules="required"
                      v-slot="{ passed, failed }"
                    >
                      <div class="form-group" style="width: 50%">
                        <el-select
                          v-model="pointAdjustment.point_type"
                          class="select-info"
                          placeholder="Select..."
                          size="large"
                          :disabled="isDisableInput"
                        >
                          <el-option
                            v-for="(option, i) in pointType"
                            :key="i"
                            :label="option.name"
                            :value="option.value"
                            class="select-info"
                          ></el-option>
                        </el-select>
                        <span
                          class="text-danger invalid-feedback"
                          style="display: block;"
                        >
                          {{
                            failed
                              ? `The Required select field is required`
                              : null
                          }}
                        </span>
                      </div>
                    </ValidationProvider>
                  </el-col>
                </el-row>
                <el-row align="center">
                  <el-col :span="6" class="col-form-label">
                    <label>Adjustment Type*</label>
                  </el-col>
                  <el-col :span="18">
                    <ValidationProvider
                      name="adjustment-type"
                      rules="required"
                      v-slot="{ passed, failed }"
                    >
                      <div class="form-group" style="width: 50%">
                        <el-select
                          v-model="pointAdjustment.point_value_type"
                          class="select-info"
                          placeholder="Select..."
                          size="large"
                          :disabled="isDisableInput"
                        >
                          <el-option
                            v-for="(option, i) in adjustmentType"
                            :key="i"
                            :label="option.name"
                            :value="option.value"
                            class="select-info"
                          ></el-option>
                        </el-select>
                        <span
                          class="text-danger invalid-feedback"
                          style="display: block;"
                        >
                          {{
                            failed
                              ? `The Required select field is required`
                              : null
                          }}
                        </span>
                      </div>
                    </ValidationProvider>
                  </el-col>
                </el-row>
                <el-row align="center">
                  <el-col :span="6" class="col-form-label">
                    <label>Basic Point Value*</label>
                  </el-col>
                  <el-col :span="18">
                    <ValidationProvider
                      name="basic-point"
                      rules="required|min_value:1"
                      v-slot="{ passed, failed }"
                    >
                      <fg-input
                        v-model="pointAdjustment.point_value"
                        class="member-input"
                        placeholder="Basic Point"
                        :disabled="isDisableInput"
                        :error="
                          failed
                            ? 'This field is required and have minimum amount 1 and cannot be negative'
                            : null
                        "
                        :hasSuccess="passed"
                      ></fg-input>
                    </ValidationProvider>
                  </el-col>
                </el-row>
                <el-row align="center">
                  <el-col :span="6" class="col-form-label">
                    <label>Bonus Point Value</label>
                  </el-col>
                  <el-col :span="18">
                    <ValidationProvider
                      name="bonus-point"
                      rules="min_value:1"
                      v-slot="{ passed, failed }"
                    >
                      <fg-input
                        v-model="pointAdjustment.bonus_point_value"
                        class="member-input"
                        placeholder="Bonus Point Value"
                        :disabled="isDisableInput"
                        :error="
                          failed
                            ? 'This field should have minimum amount 1 and cannot be negative'
                            : null
                        "
                        :hasSuccess="passed"
                      ></fg-input>
                    </ValidationProvider>
                  </el-col>
                </el-row>
                <el-row align="center">
                  <el-col class="col-form-label">
                    <label>Description*</label>
                  </el-col>
                  <el-col>
                    <ValidationProvider
                      name="description"
                      rules="required"
                      v-slot="{ passed, failed }"
                    >
                      <fg-input
                        :error="failed ? 'This field is required' : null"
                        :hasSuccess="passed"
                        :disabled="isDisableInput"
                      >
                        <el-input
                          type="textarea"
                          :rows="2"
                          placeholder="Description"
                          v-model="pointAdjustment.description"
                          :disabled="isDisableInput"
                        ></el-input>
                      </fg-input>
                    </ValidationProvider>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :md="12" :xs="24">
                <el-row align="center">
                  <!-- Package-->
                  <el-col
                    :span="transactionDetailSpan.right.label"
                    class="col-form-label"
                  >
                    <label>Package Room Revenue</label>
                  </el-col>
                  <el-col :span="transactionDetailSpan.right.input">
                    <fg-input
                      v-model="pointAdjustment.room_revenue"
                      class="member-input"
                      placeholder="Package Room Revenue"
                      :disabled="isDisableInput"
                    ></fg-input>
                  </el-col>
                </el-row>
                <el-row align="center">
                  <el-col
                    :span="transactionDetailSpan.right.label"
                    class="col-form-label"
                  >
                    <label>Package FB Revenue</label>
                  </el-col>
                  <el-col :span="transactionDetailSpan.right.input">
                    <fg-input
                      v-model="pointAdjustment.fb_revenue"
                      class="member-input"
                      placeholder="Package FB Revenue"
                      :disabled="isDisableInput"
                    ></fg-input>
                  </el-col>
                </el-row>
                <el-row align="center">
                  <el-col
                    :span="transactionDetailSpan.right.label"
                    class="col-form-label"
                  >
                    <label>Package Other Revenue</label>
                  </el-col>
                  <el-col :span="transactionDetailSpan.right.input">
                    <fg-input
                      v-model="pointAdjustment.other_revenue"
                      class="member-input"
                      placeholder="Package Other Revenue"
                      :disabled="isDisableInput"
                    ></fg-input>
                  </el-col>
                </el-row>
                <el-row align="center">
                  <el-col
                    :span="transactionDetailSpan.right.label"
                    class="col-form-label"
                  >
                    <label>Package SPA Revenue</label>
                  </el-col>
                  <el-col :span="transactionDetailSpan.right.input">
                    <fg-input
                      v-model="pointAdjustment.spa_revenue"
                      class="member-input"
                      placeholder="Package SPA Revenue"
                      :disabled="isDisableInput"
                    ></fg-input>
                  </el-col>
                </el-row>
                <el-row align="center">
                  <!-- Non-Package-->
                  <el-col
                    :span="transactionDetailSpan.right.label"
                    class="col-form-label"
                  >
                    <label>Non-Package Room Revenue</label>
                  </el-col>
                  <el-col :span="transactionDetailSpan.right.input">
                    <fg-input
                      v-model="pointAdjustment.non_package_room_revenue"
                      class="member-input"
                      placeholder="Non-Package Room Revenue"
                      :disabled="isDisableInput"
                    ></fg-input>
                  </el-col>
                </el-row>
                <el-row align="center">
                  <el-col
                    :span="transactionDetailSpan.right.label"
                    class="col-form-label"
                  >
                    <label>Non-Package FB Revenue</label>
                  </el-col>
                  <el-col :span="transactionDetailSpan.right.input">
                    <fg-input
                      v-model="pointAdjustment.non_package_fb_revenue"
                      class="member-input"
                      placeholder="Non-Package FB Revenue"
                      :disabled="isDisableInput"
                    ></fg-input>
                  </el-col>
                </el-row>
                <el-row align="center">
                  <el-col
                    :span="transactionDetailSpan.right.label"
                    class="col-form-label"
                  >
                    <label>Non-Package Other Revenue</label>
                  </el-col>
                  <el-col :span="transactionDetailSpan.right.input">
                    <fg-input
                      v-model="pointAdjustment.non_package_other_revenue"
                      class="member-input"
                      placeholder="Non-Package Other Revenue"
                      :disabled="isDisableInput"
                    ></fg-input>
                  </el-col>
                </el-row>
                <el-row align="center">
                  <el-col
                    :span="transactionDetailSpan.right.label"
                    class="col-form-label"
                  >
                    <label>Non-Package SPA Revenue</label>
                  </el-col>
                  <el-col :span="transactionDetailSpan.right.input">
                    <fg-input
                      v-model="pointAdjustment.non_package_spa_revenue"
                      class="member-input"
                      placeholder="Non-Package SPA Revenue"
                      :disabled="isDisableInput"
                    ></fg-input>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <h4>Upload Document</h4>
                <p>
                  <label><i>You need to upload at least one document</i></label>
                </p>
                <hr />
                <el-row>
                  <el-col>
                    <el-table
                      :data="pointAdjustment.documents"
                      :show-header="false"
                    >
                      <el-table-column prop="url" min-width="40">
                        Upload File
                      </el-table-column>
                      <el-table-column
                        label="Name"
                        min-width="200"
                        prop="member"
                      >
                        <template slot-scope="props">
                          <el-upload
                            :before-upload="onBeforeUpload"
                            :on-error="uploadError"
                            :action="uploadURL"
                            :headers="uploadHeader"
                            :show-file-list="false"
                            :on-success="saveToState"
                            style="display: block"
                            :disabled="isDisableInput"
                          >
                            <ValidationProvider
                              name="basic-point"
                              rules="required"
                              v-slot="{ passed, failed }"
                            >
                              <fg-input
                                slot="trigger"
                                @click="getIndex(props.$index)"
                                :value="props.row.url"
                                class="member-input"
                                placeholder="Browse..."
                                style="width: 500px"
                                :disabled="loading.uploadFile || isDisableInput"
                                :error="
                                  failed ? 'This field is required' : null
                                "
                                :hasSuccess="passed"
                              ></fg-input>
                            </ValidationProvider>
                          </el-upload>
                        </template>
                      </el-table-column>
                      <el-table-column prop="row" align="right">
                        <template slot-scope="props">
                          <n-button
                            type="warning"
                            size="sm"
                            icon
                            :disabled="props.row.url === ''"
                            @click.native="viewDocument(props.row.url)"
                            class="mr-1"
                          >
                            <i class="now-ui-icons tech_tv"></i>
                          </n-button>
                          <n-button
                            type="danger"
                            size="sm"
                            icon
                            :disabled="alreadyApproved"
                            @click.native="removeDocument(props.$index)"
                          >
                            <i class="now-ui-icons ui-1_simple-remove"></i>
                          </n-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-col>
                </el-row>
                <button
                  class="btn pull-right btn-info"
                  @click="approve"
                  v-if="isApprove"
                  type="button"
                  :disabled="onSubmit || !hasRole('approval') || alreadyApproved"
                >
                  APPROVE
                </button>
                <button
                  class="btn pull-right btn-border"
                  @click="addMoreDocument"
                  type="button"
                  :disabled="alreadyApproved"
                  v-else
                >
                  MORE DOCUMENT
                </button>
              </el-col>
            </el-row>
          </card>
          <n-button
            native-type="submit"
            type="info"
            :disabled="
              onSubmit || !isRequestor || alreadyApproved || !hasRole('create')
            "
            v-if="!isApprove"
          >
            SUBMIT
          </n-button>
        </el-col>
      </el-row>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  Col,
  DatePicker,
  Loading,
  MessageBox,
  Option,
  Row,
  Select,
  Table,
  TableColumn,
  Upload
} from "element-ui";
import { mapActions, mapMutations, mapState } from "vuex";
import { ACCESS_TOKEN, BASE_URL_TADA_AYANA } from "@/config";
import { extend } from "vee-validate";
import { required, min_value } from "vee-validate/dist/rules";
import Swal from "sweetalert2";
import { findUserRole } from "@/util/userRoles";
import moment from "moment";

extend("required", required);
extend("min_value", min_value);

export default {
  name: "PointAdjustmentForm",
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Option.name]: Option,
    [Select.name]: Select,
    [DatePicker.name]: DatePicker,
    [Upload.name]: Upload,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      onSubmit: false,
      isApprove: false,
      currentIndex: 0,
      transactionDetailSpan: {
        right: {
          label: 8,
          input: 16
        }
      },
      pickerOptions1: {
        shortcuts: [
          {
            text: "Today",
            onClick(picker) {
              picker.$emit("pick", new Date());
            }
          },
          {
            text: "Yesterday",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            }
          },
          {
            text: "A week ago",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            }
          }
        ]
      },
      document: {
        url: "",
        mime_type: "",
        ext: ""
      },
      pointType: [
        { name: "Spa Manual", value: "SPA MANUAL" },
        { name: "Room Manual", value: "ROOM MANUAL" },
        { name: "FB Manual", value: "FB MANUAL" },
        { name: "Promotion", value: "PROMOTION" },
        { name: "Other Manual", value: "OTHER MANUAL"},
        { name: "Wedding", value: "WEDDING" },
        { name: "Activity", value: "ACTIVITY" },
      ],
      adjustmentType: [
        { name: "Earn", value: "EARN" },
        { name: "Deduct", value: "DEDUCT" }
      ],
      loading: {
        guestName: false,
        uploadFile: false,
        loadDetail: false
      },
      uploadURL: `${BASE_URL_TADA_AYANA}/point-adjustment/upload`
    };
  },
  created() {
    this.getPlaceOfVisit();
    this.RESET_POINT_ADJUSTMENT();

    this.isApprove =
      this.currentRouteName ===
      "Manual Adjustment - Approve Point Adjustment Form";

    if (
      this.currentRouteName === "Manual Adjustment - New Point Adjustment Form"
    ) {
      if (this.$route.query.member) {
        const member = JSON.parse(this.$route.query.member);
        this.SET_MEMBER_DETAIL(member);
      }
    }

    if (
      this.currentRouteName === "Manual Adjustment - Point Adjustment Form" ||
      this.currentRouteName ===
      "Manual Adjustment - Approve Point Adjustment Form"
    ) {
      this.loading.loadDetail = true;
      this.getPointAdjustmentDetail(this.$route.params.id)
        .then(response => {
          // if (this.currentRouteName === "Manual Adjustment - Point Adjustment Form"){
          //   this.isApprove = !(this.name === response.data.data.requestor_name);
          // }
        })
        .finally(() => {
          this.loading.loadDetail = false;
        });
    }
  },
  computed: {
    ...mapState("pointAdjustment", [
      "pointAdjustment",
      "filter",
      "placeOfVisit",
      "guest",
      "guestLoading"
    ]),
    ...mapState("auth", ["name", "userId"]),
    uploadHeader() {
      const token = localStorage.getItem(ACCESS_TOKEN);
      return {
        Authorization: `Bearer ${token}`
      };
    },
    currentRouteName() {
      return this.$route.name;
    },
    isDisableInput() {
      return this.isApprove && this.loading.loadDetail;
    },
    alreadyApproved() {
      if (this.pointAdjustment !== undefined) {
        return this.pointAdjustment.approval_id != null;
      }

      return false;
    },
    isRequestor() {
      if (
        this.currentRouteName === "Manual Adjustment - Point Adjustment Form"
      ) {
        return this.pointAdjustment.requestor_id === this.userId;
      }
      return true;
    }
  },
  methods: {
    ...mapActions("pointAdjustment", [
      "getPlaceOfVisit",
      "searchGuest",
      "createPointAdjustment",
      "searchByConfirmationNo",
      "updatePointAdjustment",
      "getPointAdjustmentDetail",
      "approvePointAdjustment"
    ]),
    ...mapMutations("pointAdjustment", [
      "ADD_MORE_DOCUMENT",
      "REMOVE_DOCUMENT",
      "SET_GUEST_LOADING",
      "SET_MEMBER_DETAIL",
      "ADD_UPLOADED_DOCUMENT",
      "RESET_POINT_ADJUSTMENT",
      "SET_SEARCH_BY_CONFIRMATION"
    ]),
    onBeforeUpload(file) {
      const isLessThanOrEqualTo10M = file.size / 1024 / 1024 <= 10;

      if (!isLessThanOrEqualTo10M) {
        this.$notify({
          message: "Upload file size cannot exceed 10 MB!",
          timeout: 5000,
          icon: "now-ui-icons ui-1_bell-53",
          horizontalAlign: "center",
          verticalAlign: "bottom",
          type: "danger"
        });
      }

      return isLessThanOrEqualTo10M;
    },
    validateConfirmationNo(event) {
      const confirmationNo = event.target.value;

      this.searchByConfirmationNo(confirmationNo).then(() => {
        MessageBox.alert(
          `It looks like your confirmation no: ${confirmationNo} has been used in other point adjustment.`,
          "Information",
          {
            confirmButtonText: "OK"
          }
        );
      });
    },
    uploadError() {
      MessageBox.alert("Unable to upload file, please try again.", "Failed", {
        confirmButtonText: "OK"
      });
    },
    hasRole(role) {
      return findUserRole(role, "manual_point_adjustment");
    },
    addMoreDocument() {
      this.ADD_MORE_DOCUMENT({ ...this.document });
    },
    removeDocument(i) {
      this.REMOVE_DOCUMENT(i);
    },
    viewDocument(url) {
      window.open(url);
    },
    searchGuestName(query) {
      this.searchGuest(query);
    },
    autoCompleteMember(data) {
      this.SET_MEMBER_DETAIL(data);
    },
    getIndex(i) {
      this.currentIndex = i;
      this.loading.uploadFile = true;
    },
    saveToState(response, file, fileList) {
      this.ADD_UPLOADED_DOCUMENT({
        index: this.currentIndex,
        data: response.data
      });
      this.loading.uploadFile = false;
    },
    submitForm() {
      let visitFromTimestamp = null;
      let visitToTimestamp = null;
      let message = null;

      if (this.pointAdjustment.visit_from != null) {
        visitFromTimestamp = moment(
          this.pointAdjustment.visit_from,
          "YYYY-MM-DD"
        ).unix();
      }

      if (this.pointAdjustment.visit_to != null) {
        visitToTimestamp = moment(
          this.pointAdjustment.visit_to,
          "YYYY-MM-DD"
        ).unix();
      }

      if (visitToTimestamp != null && visitFromTimestamp == null) {
        message = "Visit From must not be empty.";
      } else if (visitToTimestamp == null && visitFromTimestamp != null) {
        message = "Visit To must not be empty.";
      } else if (
        visitFromTimestamp != null &&
        visitToTimestamp != null &&
        visitFromTimestamp > visitToTimestamp
      ) {
        message = "Visit To must be greater than Visit From.";
      }

      if (message != null) {
        MessageBox.alert(message, "Invalid Date of Visit", {
          confirmButtonText: "OK"
        });

        return;
      }

      this.onSubmit = true;
      let loadingInstance = Loading.service({
        fullscreen: true
      });
      if (
        this.currentRouteName ===
        "Manual Adjustment - New Point Adjustment Form"
      ) {
        this.createPointAdjustment()
          .then(() => {
            loadingInstance.close();

            Swal.fire({
              icon: "success",
              title: "Create Point Adjustment success",
              text: "Create saved",
              onClose: () => {
                this.$router.push({ name: "Point Adjustment List" });
              }
            });
          })
          .catch(() => {
            loadingInstance.close();
            this.$notify({
              message: "Create point adjustment failed",
              timeout: 5000,
              icon: "now-ui-icons ui-1_bell-53",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger"
            });
          })
          .finally(() => {
            loadingInstance.close();
            this.onSubmit = false;
          });
      } else {
        this.updatePointAdjustment(this.$route.params.id)
          .then(() => {
            loadingInstance.close();

            Swal.fire({
              icon: "success",
              title: "Update Point Adjustment success",
              text: "Update saved",
              onClose: () => {
                this.$router.push({ name: "Point Adjustment List" });
              }
            });
          })
          .catch(() => {
            loadingInstance.close();
            this.$notify({
              message: "Create point adjustment failed",
              timeout: 5000,
              icon: "now-ui-icons ui-1_bell-53",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger"
            });
          })
          .finally(() => {
            loadingInstance.close();
            this.onSubmit = false;
          });
      }
    },
    approve() {
      let loadingInstance = Loading.service({
        fullscreen: true
      });
      this.approvePointAdjustment(this.$route.params.id)
        .then(() => {
          this.$router.push({ name: "Point Adjustment List" });
        })
        .catch(() => {
          loadingInstance.close();
          this.$notify({
            message: "Approve point adjustment failed",
            timeout: 5000,
            icon: "now-ui-icons ui-1_bell-53",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger"
          });
        })
        .finally(() => {
          loadingInstance.close();

          Swal.fire({
            icon: "success",
            title: "You have approved point adjustment request",
            text: "Approve success",
            onClose: () => {
              this.$router.push({ name: "Point Adjustment List" });
            }
          });

          this.onSubmit = false;
        });
    }
  }
};
</script>

<style scoped>
.card-padding {
  padding-left: 20px;
  padding-right: 30px;
}
</style>
